<template>
 <div>
   <a-modal title="选择商品" v-model="visible" @ok="toSubmit" okText="确定" :width="1000" :maskClosable="false">
     <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
        :columns="columns" :data-source="tableData" bordered :pagination="page"
        @change="changeTable" :customRow="changeTableRow">
     </a-table>
   </a-modal>
 </div>
</template>

<script>
import { ProColumns } from './colums.js'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0
}
 export default {
  data() {
    return {
      visible: false,
      tableData: [],
      columns: ProColumns,
      tableLoading: false,
      page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created() {},
   computed: {
     rowSelection() {
       const _this = this
       return {
         fixed: true,
         // type: 'radio', //"radio"
         selectedRowKeys: this.selectedRowKeys,
         onChange: (selectedRowKeys, selectedRows) => {
           _this.selectedRowKeys = selectedRowKeys
           _this.selectedRows = selectedRows
         }
       }
     }
   },
  methods: {
    isShow() {
      this.visible = true
      this.getList()
    },
    toSubmit() {
      this.$emit('add-product', this.selectedRows)
      this.selectedRowKeys = []
      this.selectedRows = []
      this.visible = false
    },
    getList() {
      this.axios.get('/api/product/system/goods/list').then(res => {
        this.tableData = res.body.records
      })
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: e => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          }
        }
      }
    },
  }
 }
</script>

<style scoped>

</style>